const pages = [
  {
    groupTitle: 'Main',
    id: 'main',
    pages: [
      {
        title: 'Invite Users',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/inviteUser',
      },
      {
        title: 'Whitelabel Customers',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/customers',
      },
      {
        title: 'Whitelabel Clients',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/clients',
      },
      {
        title: 'Organizations',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/org/v1',
      },
      {
        title: 'Organizations v2',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/org/v2',
      },
      {
        title: 'Users',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/users',
      },
      {
        title: 'Practice Call Links',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-call-links',
      },
      {
        title: 'Prompts',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/prompts/v1',
      },
      {
        title: 'Prompts v2',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/prompts/v2',
      },
      {
        title: 'Bots',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots',
      },
      {
        title: 'Preset Questions',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/preset-questions',
      },
      {
        title: 'Fine Tune Models',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/fine-tune-models',
      },
    ],
  },
  {
    groupTitle: 'Secondary',
    id: 'secondary',
    pages: [
      {
        title: 'Default Verticals & Designations',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/dvd',
      },
      {
        title: 'Plans & Add-ons',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/all-plans',
      },
      {
        title: 'Playbook Generation',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/playbook-generation',
      },
      {
        title: 'Privacy Policy & ToS',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/privacy-tos',
      },
      {
        title: 'Sales Call Analysis(Users)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis-users',
      },
      {
        title: 'Sales Call Analysis(Admin)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/sales-call-analysis',
      },
      {
        title: 'Roleplays(Users)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls',
      },
      {
        title: 'Roleplays(Admin)',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/practice-calls-admin',
      },
      {
        title: 'Bots Requests',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/bots-requests',
      },
      {
        title: 'Demo Requests',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/demo-requests',
      },
      {
        title: 'AWS transcribe poc',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/aws-transcribe-poc',
      },
      {
        title: 'Frontend Transcribe POC',
        href: '/f550aeea-0bb4-41e9-b615-7a6cf101e740/fe-transcribe-poc',
      },
    ],
  },
];

export default pages;
